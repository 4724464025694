import {
  Accordion, AccordionDetails, AccordionSummary, Typography, Box,
} from '@mui/material';
import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import type { ErpSyncOrderWithLinkAndTasks } from '../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../sharedutils/index.js';

type ERPOverviewTaskDialogContentProps = {
  row: ErpSyncOrderWithLinkAndTasks | undefined
};

/**
 * Maps task url to the readable endpoint
 * @param task
 */
const mapTaskToText = (task: ErpSyncOrderWithLinkAndTasks['tasks'][number]) => {
  if (!task.httpRequest?.url) return '';
  switch (true) {
    case task.httpRequest.url.includes('createOrderFromAC'):
      return 'Opprette 24SO FJ Klima ordre';
    case task.httpRequest.url.includes('findMRPOrder'):
      return 'Finne Masterplan ordre etter 24SO FJ Klima ordre er oppretta. Dette fikser også Warehouse';
    case task.httpRequest.url.includes('findFJOrderAfterMRPInvoiced'):
      return 'Fakturering: Finne NY 24SO FJ Klima ordre etter Masterplan ordre er fakturert (steg 5 av fakturering)';
    case task.httpRequest.url.includes('pickOrder'):
      return 'Fakturering: Plukke ordrelinjer i Masterplan (steg 2)';
    case task.httpRequest.url.includes('shipOrder'):
      return 'Fakturering: Ship i Masterplan (steg 3)';
    case task.httpRequest.url.includes('invoiceOrder'):
      return 'Fakturering: Fakturere ordre i Masterplan (steg 4)';
    case task.httpRequest.url.includes('/ac/createOrder'):
      return 'Opprette 24SO AC Ordre';
    case task.httpRequest.url.includes('checklistInstalled'):
      return 'Fakturering: Sjekke monteringsordre og evt. oppdatere 24SO AC(T) og Masterplan ordre med nye ordrelinjer / fjerne ordrelinjer. Antall blir også oppdatert ved endring. Ved serviceordre vil den sette ordre til fakturering her om sum er innenfor grensen.';
    default:
      return 'Ukjent task';
  }
};

const ERPOverviewTaskDialogContent = (props: ERPOverviewTaskDialogContentProps) => {
  const { row: erpSync } = props;

  return (
    <Box>
      {!erpSync ? 'Tomt' : erpSync.tasks.map((task) => (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>{task.httpRequest?.url}</AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" gutterBottom>
              <b>Beskrivelse:</b>
              {' '}
              {mapTaskToText(task)}
            </Typography>
            <Typography>
              <b>Siste forsøk:</b>
              {' '}
              {DateUtils.getLocalizedDateTime(task.lastAttempt?.dispatchTime)}
              {'. Melding: '}
              {task.lastAttempt?.responseStatus?.message}
            </Typography>
            <Typography>
              <b>Neste forsøk:</b>
              {' '}
              {DateUtils.getLocalizedDateTime(task.scheduleTime)}
            </Typography>
            <Typography>
              <b>Antall forsøk:</b>
              {' '}
              {task.dispatchCount}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ERPOverviewTaskDialogContent;
