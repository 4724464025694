import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import {
  Divider,
  Typography, Box,
} from '@mui/material';
import { MonitorQueries } from '../../../queries/index.js';
import type { NotificationChannelsResponseInner } from '../../../../generated/activityhelper/api.js';
import type { DialogRowElementProps } from '../../table/GenericTable.js';

import PolicyNotifForm from '../../monitor/notificationChannel/PolicyNotifForm.js';

const NotificationChannelDialogContent = (props: DialogRowElementProps<NotificationChannelsResponseInner>) => {
  const { row } = props;
  const { data } = useQuery(MonitorQueries.policiesQuery);

  const policiesData = useMemo(() => data ?? [], [data]);

  if (!row) return null;

  const {
    type, value, rateLimit, extraConfig, policies, id,
  } = row;

  const policyIdsNotUsed = policiesData.map((p) => p.id).filter((pId) => !policies.map((p) => p.policyId).includes(pId));

  return (
    <Box>
      <Typography>
        <b>Type:</b>
        {' '}
        {type}
      </Typography>
      <Typography>
        <b>Value:</b>
        {' '}
        {value}
      </Typography>
      <Typography>
        <b>Rate Limit (sec):</b>
        {' '}
        {rateLimit}
      </Typography>
      <Typography gutterBottom><b>Extra config:</b></Typography>
      {extraConfig ? (
        <pre><code>{JSON.stringify(extraConfig ?? {}, undefined, 2)}</code></pre>
      ) : <Typography>No extra config</Typography>}
      <Divider sx={{ my: 2 }} />
      <Typography gutterBottom sx={{ mb: 2 }}><b>Policies:</b></Typography>
      {policies.map((p) => (
        <PolicyNotifForm
          key={`${id}_${p.policyId}`}
          policyNotif={p}
          policies={policiesData}
          notificationId={id}
          policyIdsNotUsed={policyIdsNotUsed}
        />
      ))}
      <PolicyNotifForm
        policies={policiesData}
        notificationId={id}
        policyIdsNotUsed={policyIdsNotUsed}
      />
    </Box>
  );
};

export default NotificationChannelDialogContent;
