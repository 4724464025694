import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import {
  FormControl, InputLabel, MenuItem, Select, TextField,
  Tooltip, Box, Grid2,
} from '@mui/material';
import { z } from 'zod';
import { MonitorMutations } from '../../../mutations/index.js';
import type {
  NotificationChannelsResponseInner, UpdateNotificationChannelCommand,
  CreateNotificationChannelCommand,
} from '../../../../generated/activityhelper/api.js';
import {
  CreateNotificationChannelCommandTypeEnum,
} from '../../../../generated/activityhelper/api.js';

import LoadingButton from '../../common/buttons/LoadingButton.js';
import { MonitorQueries } from '../../../queries/index.js';
import { ZodValidation } from '../../../utils/index.js';

type NotificationChannelFormProps = {
  edit?: {
    value: NotificationChannelsResponseInner,
  },
  handleClose: () => void,
};

const NotificationChannelForm = (props: NotificationChannelFormProps) => {
  const queryClient = useQueryClient();
  const { edit, handleClose } = props;
  const createNotif = useMutation(MonitorMutations.createNotificationChannelMutation);
  const updateNotif = useMutation(MonitorMutations.updateNotificationChannelMutation);
  const form = useForm<CreateNotificationChannelCommand | UpdateNotificationChannelCommand>({
    defaultValues: edit?.value ? {
      rateLimit: edit.value.rateLimit,
      type: edit.value.type,
      value: edit.value.value,
      extraConfig: edit.value.extraConfig,
    } : {
      rateLimit: 300,
      type: 'EMAIL',
      value: '',
      extraConfig: null as unknown as object,
    },
    onSubmit: async ({ value }) => {
      if (edit) {
        await updateNotif.mutateAsync({ id: edit.value.id.toString(), updateNotificationChannelCommand: value });
      } else {
        await createNotif.mutateAsync({ createNotificationChannelCommand: value });
      }
      queryClient.invalidateQueries(MonitorQueries.notificationChannelsQuery);
      handleClose();
    },
    // validatorAdapter: zodValidator(),
  });

  return (
    (
      <Box>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        >
          <Grid2 container spacing={2} direction="column">
            <Grid2>
              <form.Field
                name="value"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: ZodValidation.emailSchema,
                }}
              >
                {(field) => (
                  <Tooltip title="The email address to send the notification to">
                    <TextField
                      type="text"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      onBlur={field.handleBlur}
                      label="Value"
                      error={!!field.state.meta.errors.length}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  </Tooltip>
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Field
                name="rateLimit"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: z.number().min(120),
                }}
              >
                {(field) => (
                  <Tooltip title="How often to send the notification">
                    <TextField
                      type="number"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(Number(e.target.value))}
                      onBlur={field.handleBlur}
                      label="Rate Limit (in seconds)"
                    />
                  </Tooltip>
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Field name="type">
                {(field) => (
                  <FormControl>
                    <InputLabel id="select_type_label" shrink sx={{ backgroundColor: 'white' }}>
                      Type
                    </InputLabel>
                    <Select
                      labelId="select_type_label"
                      displayEmpty
                      label="Type"
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value as CreateNotificationChannelCommandTypeEnum)}
                      fullWidth
                      sx={{ width: 100 }}
                      disabled
                    >
                      {Object.values(CreateNotificationChannelCommandTypeEnum).map((v) => (
                        <MenuItem key={v} value={v}>{v}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </form.Field>
            </Grid2>
            <Grid2
              container
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <LoadingButton
                    buttonProps={{
                      variant: 'contained',
                      type: 'submit',
                      loading: isSubmitting,
                      disabled: !canSubmit || isSubmitting,
                      color: edit ? undefined : 'success',
                    }}
                    save
                  >
                    {edit ? 'Update' : 'Add'}
                  </LoadingButton>
                )}
              </form.Subscribe>
            </Grid2>
          </Grid2>
        </form>
      </Box>
    )
  );
};

export default NotificationChannelForm;
