import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import type {
  DefaultApiApiErpErpSyncOrdersGetRequest,
  EndpointTask,
  ErpSyncOrderWithLinkAndTasks,
  GetErpErrorRequestQuery,
  GetErpErrorResponse,
  GetErpSyncOrderCommand, GetErpSyncOrderResponse, GetMRPSalesOrderResponse,
  MrpPickErrorList,
  GetMrpPickErrorsRequest,
  GetPickErrorDeviationReasonsResponseReasonsInner,
} from '../../generated/activityhelper/api.js';
import { monitorApi } from '../apis/apis.js';

interface ERPQueries {
  getErpSyncOrderQuery(command: GetErpSyncOrderCommand | undefined): UseQueryOptions<GetErpSyncOrderResponse | undefined>;
  getErpSyncOrdersQuery(command: DefaultApiApiErpErpSyncOrdersGetRequest | undefined): UseQueryOptions<{
    erpSyncOrders: ErpSyncOrderWithLinkAndTasks[],
    endpointTasks: EndpointTask[] }>;
  getMRPSalesOrderQuery(cpproId: string | undefined): UseQueryOptions<GetMRPSalesOrderResponse | undefined>;
  getMRPPickErrorsForOrderQuery(orderId: string | undefined): UseQueryOptions<MrpPickErrorList>;
  getMRPPickErrorsQuery(command: GetMrpPickErrorsRequest): UseQueryOptions<MrpPickErrorList>;
  getErpErrorsQuery(command: GetErpErrorRequestQuery): UseQueryOptions<GetErpErrorResponse['erpErrors']>;
}

export const getErpSyncOrderQuery: ERPQueries['getErpSyncOrderQuery'] = (command) => ({
  queryKey: ['erp-sync-order', command],
  queryFn: async () => {
    if (!command) return undefined;
    const { data } = await monitorApi.apiErpErpSyncOrderGet({ query: command });
    return data;
  },
  enabled: !!command,
  refetchOnWindowFocus: false,
});

export const getErpSyncOrdersQuery: ERPQueries['getErpSyncOrdersQuery'] = (command) => ({
  queryKey: ['erp-sync-orders', command],
  queryFn: async () => {
    const { data } = await monitorApi.apiErpErpSyncOrdersGet(command);
    return data ?? [];
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: !!command,
});

export const getMRPSalesOrderQuery: ERPQueries['getMRPSalesOrderQuery'] = (cpproId) => ({
  queryKey: ['mrp-sales-order', cpproId],
  queryFn: async () => {
    if (!cpproId) return undefined;
    const { data } = await monitorApi.apiErpOrderCpproIdMrpSalesOrderGet({ cpproId });
    return data;
  },
  enabled: !!cpproId,
});

export const mrpPickErrorsQueryKey: QueryKey = ['mrp-pick-errors'];

export const getMRPPickErrorsQuery: ERPQueries['getMRPPickErrorsQuery'] = (command) => ({
  queryKey: [...mrpPickErrorsQueryKey, command],
  queryFn: async () => {
    const { data } = await monitorApi.apiErpMrpPickErrorsGet(command);
    return data.pickErrors as MrpPickErrorList;
  },
});

export const mrpPickErrorsForOrderQueryKey: QueryKey = ['mrp-pick-errors-for-order'];

export const getMRPPickErrorsForOrderQuery: ERPQueries['getMRPPickErrorsForOrderQuery'] = (orderId) => ({
  queryKey: [...mrpPickErrorsForOrderQueryKey, { orderId }],
  queryFn: async () => {
    if (!orderId) return [];
    const { data } = await monitorApi.apiErpMrpOrderOrderIdPickErrorsGet({ orderId });
    return data.pickErrors as MrpPickErrorList;
  },
  enabled: !!orderId,
});

export const getErpErrorsQuery: ERPQueries['getErpErrorsQuery'] = (command) => ({
  queryKey: ['erp-errors', command],
  queryFn: async () => {
    const { data } = await monitorApi.monitorErpErrorsGet({ query: command });
    return data.erpErrors;
  },
});

export const pickErrorDeviationReasonsQuery: UseQueryOptions<GetPickErrorDeviationReasonsResponseReasonsInner[]> = {
  queryKey: ['pick-error-deviation-reasons'],
  queryFn: async () => {
    const { data } = await monitorApi.apiErpMrpPickErrorDeviationReasonsGet();
    return data.reasons ?? [];
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};
