import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Autocomplete, MenuItem, Select, TextField, Typography, Box, Grid2,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CpproUserContext from '../../../contexts/CpproUserContext.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import { UserPropertyType } from '../../../../CPpro/model/model.js';
import removeNullsAndUndefined from '../../../../CPpro/Utils/commonUtils.js';
import { AccessTypes } from '../../../types/index.js';
import { CPproQueries } from '../../../queries/index.js';
import { CPproMutations } from '../../../mutations/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

const MonitorAdministrationPage = () => {
  const { user, refreshUser } = useContext(CpproUserContext);

  const queryClient = useQueryClient();
  const onSuccess = () => {
    refreshUser();
    queryClient.invalidateQueries(CPproQueries.usersQuery);
  };
  const { data: usersQueryData, isLoading: usersIsLoading } = useQuery(CPproQueries.usersQuery);
  const addUserRole = useMutation({ ...CPproMutations.addUserRole, onSuccess });
  const updateUserRole = useMutation({ ...CPproMutations.updateUserRole, onSuccess });
  const deleteUserRole = useMutation({ ...CPproMutations.deleteUserRole, onSuccess });

  const isLoading = useMemo<boolean>(
    () => usersIsLoading
    || addUserRole.isPending
    || updateUserRole.isPending
    || deleteUserRole.isPending,
    [usersIsLoading, addUserRole.isPending, updateUserRole.isPending, deleteUserRole.isPending],
  );

  const users = useMemo(() => (usersQueryData ?? []), [usersQueryData]);

  const [selectedUser, setSelectedUser] = useState<{ email: string, id: number } | null>(null);
  const [selectedUserRoles, setSelectedUserRoles] = useState<AccessTypes.UserAccessRoleProperty[]>([]);
  const [originalUserRoles, setOriginalUserRoles] = useState<AccessTypes.UserAccessRoleProperty[]>([]);

  const userOptions = useMemo(() => users
    .map((u) => {
      const email = u.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString().trim();
      if (!email) return undefined;
      return { email, id: u.id };
    })
    .filter(removeNullsAndUndefined)
    .toSorted((a, b) => a.email.localeCompare(b.email)), [users]);

  useEffect(() => {
    if (selectedUser) {
      const found = users.find((u) => u.id === selectedUser.id);
      if (found) {
        const roles = found.properties
          .filter((p) => p.type === UserPropertyType.AccessRole)
          .map((p) => {
            const value = p.value.toString();
            if (!value) return undefined;
            const split = value.split(':');
            return {
              role: split[0] as AccessTypes.AccessRole,
              level: split[1] as AccessTypes.AccessRoleLevel,
              id: p.id,
              userId: found.id,
            } satisfies AccessTypes.UserAccessRoleProperty;
          })
          .filter(removeNullsAndUndefined);
        setSelectedUserRoles(roles);
        setOriginalUserRoles(structuredClone(roles));
      } else {
        setSelectedUserRoles([]);
      }
    }
  }, [selectedUser, users]);

  const onRoleChange = (role: AccessTypes.AccessRole, level: AccessTypes.AccessRoleLevel) => {
    if (selectedUser) {
      setSelectedUserRoles((prev) => {
        let copy = [...prev];

        if (level === '') {
          copy = copy.filter((r) => r.role !== role);
        } else {
          const found = copy.find((r) => r.role === role);
          if (found) found.level = level;
          if (!found) {
            copy.push({
              role, level, id: 0, userId: selectedUser.id,
            });
          }
        }
        return copy;
      });
    }
  };

  const onUserRolesSave = () => {
    // New roles (role not in original but is in new)
    const newRoles = selectedUserRoles.filter((r) => !originalUserRoles.find((or) => or.role === r.role));

    // Updated role (role in original and in new but with a diffrent level)
    const updatedRoles = selectedUserRoles
      .filter((r) => originalUserRoles.find((or) => or.role === r.role && or.level !== r.level))
      // map get property id from original user roles
      .map((r) => ({
        ...r,
        id: originalUserRoles.find((or) => or.role === r.role)!.id,
      }));

    // Deleted roles (role in original but not in new)
    const deletedRoles = originalUserRoles.filter((or) => !selectedUserRoles.find((r) => r.role === or.role));

    if (newRoles.length > 0) {
      addUserRole.mutate(newRoles);
    }
    if (updatedRoles.length > 0) {
      updateUserRole.mutate(updatedRoles);
    }
    if (deletedRoles.length > 0) {
      deleteUserRole.mutate(deletedRoles);
    }
  };

  // TODO Should use some form library to save form state

  return (
    (
      <Box>
        <MonitorPageTitle title="Administration" />
        {!user?.isUserAdmin ? (
          <Typography
            variant="h5"
            sx={{
              color: 'error',
            }}
          >
            You do not have the USERADMIN accesslevel
          </Typography>
        ) : (
          <>
            <Autocomplete
              size="small"
              options={userOptions}
              getOptionLabel={(option) => option.email}
              getOptionKey={(option) => option.id}
              sx={{ width: 300, mb: 2 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select user" />}
              value={selectedUser}
              onChange={(_, value) => setSelectedUser(value)}
            />
            {selectedUser ? (
              <Box>
                <Typography variant="h6" gutterBottom>Roles:</Typography>
                {(Object.keys(AccessTypes.AccessRoles) as AccessTypes.AccessRole[]).map((role) => (
                  <Grid2
                    container
                    key={role}
                    spacing={2}
                    sx={{
                      alignItems: 'center',
                      marginBottom: 1,
                    }}
                  >
                    <Grid2>
                      <Typography>{role}</Typography>
                    </Grid2>
                    <Grid2>
                      <Select
                        size="small"
                        value={selectedUserRoles.find((r) => r.role === role)?.level ?? ''}
                        onChange={(e) => onRoleChange(role, e.target.value as AccessTypes.AccessRoleLevel)}
                        displayEmpty
                      >
                        {(Object.values(AccessTypes.AccessRoleLevels) as AccessTypes.AccessRoleLevel[]).map((level) => (
                          <MenuItem key={level || 'NONE'} value={level}>{level || 'NONE'}</MenuItem>
                        ))}
                      </Select>
                    </Grid2>
                  </Grid2>
                ))}
                <LoadingButton
                  buttonProps={{
                    loading: isLoading,
                    sx: { mt: 1 },
                    onClick: onUserRolesSave,
                  }}
                  save
                >
                  Save
                </LoadingButton>
              </Box>
            ) : (<Typography>No user selected</Typography>)}
          </>
        )}
      </Box>
    )
  );
};

export default MonitorAdministrationPage;
