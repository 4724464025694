import { Home } from '@mui/icons-material';
import {
  AppBar, Toolbar, Link as MuiLink, Button, IconButton, Box,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const isReport = import.meta.env.MODE === 'report';
const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test';

type HeaderPage = { text: string | React.ReactNode, url: string, external?: boolean };

const reportPages: HeaderPage[] = [
  { text: 'Salgsoversikt', url: '/' },
  {
    text: 'Salgsoversikt - GAMMEL',
    url: '/old',
  },
];

const pages: HeaderPage[] = isReport ? reportPages : [
  { text: 'Stemplingsstatus', url: '/hourReport/status' },
  { text: 'Stemplingsoversikt', url: '/hourReport/overview' },
];

if (isDev) {
  pages.push(...reportPages.map((page) => ({ ...page, url: `/sales${page.url}` })));
}

const Header = () => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  (
    <AppBar position="sticky" sx={{ borderRadius: 1 }}>
      <Toolbar>
        <IconButton edge="start" LinkComponent={MuiLink} href={import.meta.env.VITE_FRONTEND_URL ?? ''}>
          <Home sx={{ color: '#fff' }} />
        </IconButton>
        <Box>
          {pages.map((page) => (page.external ? (
            <Button sx={{ color: '#fff' }} key={page.url} LinkComponent={MuiLink} href={page.url} rel="noreferrer noopener">
              {page.text}
            </Button>
          ) : (
            <Button sx={{ color: '#fff' }} key={page.url} component={Link} to={page.url}>
              {page.text}
            </Button>

          )))}
        </Box>
      </Toolbar>
    </AppBar>
  )
);

export default Header;
