import React, { useMemo } from 'react';
import {
  Typography, Box, Grid2,
} from '@mui/material';
import type { ProductStock } from './MRPStockOverviewTypes.js';
import type { GenericTableFilterProps } from '../../../table/GenericTable.js';
import AutocompleteFilter from '../../../table/filters/AutocompleteFilter.js';
import { removeFalsy } from '../../../../../CPpro/Utils/commonUtils.js';
import DebouncedGlobalFilter from '../../../table/filters/DebouncedGlobalFilter.js';
import SelectFilter from '../../../table/SelectFilter.js';
import 'core-js/actual/iterator/to-array';

const MRPStockOverviewFilter = (props: GenericTableFilterProps<ProductStock>) => {
  const { table } = props;

  const nameColumn = table.getColumn('name');
  const itemNumberColumn = table.getColumn('itemNumber');
  const categoryColumn = table.getColumn('category');
  const subCategoryColumn = table.getColumn('subCategory');

  const nameUniqueValues = nameColumn?.getFacetedUniqueValues();
  const itemNumberUniqueValues = itemNumberColumn?.getFacetedUniqueValues();
  const categoryUniqueValues = categoryColumn?.getFacetedUniqueValues();
  const subCategoryUniqueValues = subCategoryColumn?.getFacetedUniqueValues();

  const nameOptions = useMemo(
    () => (nameUniqueValues?.keys().toArray() ?? [])
      .filter(removeFalsy)
      .toSorted((a, b) => a.localeCompare(b)),
    [nameUniqueValues],
  );
  const itemNumberOptions = useMemo(
    () => (itemNumberUniqueValues?.keys().toArray() ?? [])
      .filter(removeFalsy)
      .toSorted((a, b) => a.localeCompare(b)),
    [itemNumberUniqueValues],
  );

  const categoryOptions = useMemo(
    () => (categoryUniqueValues?.keys().toArray() ?? [])
      .filter(removeFalsy)
      .toSorted((a, b) => a.localeCompare(b)),
    [categoryUniqueValues],
  );

  const subCategoryOptions = useMemo(
    () => (subCategoryUniqueValues?.keys().toArray() ?? [])
      .filter(removeFalsy)
      .toSorted((a, b) => a.localeCompare(b)),
    [subCategoryUniqueValues],
  );

  return (
    (
      <Box sx={{ padding: 1 }}>
        <Typography
          gutterBottom
          sx={{
            fontWeight: 'bold',
          }}
        >
          Filter:
        </Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
          }}
        >
          {nameColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={350} column={nameColumn} options={nameOptions} label="Produktnavn" />
            </Grid2>
          ) : null}
          {itemNumberColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={160} column={itemNumberColumn} options={itemNumberOptions} label="Produktnummer" />
            </Grid2>
          ) : null}
          <Grid2>
            <DebouncedGlobalFilter table={table} minWidth={350} label="Navn/beskrivelse inneholder" type="text" />
          </Grid2>
          {categoryColumn ? (
            <SelectFilter menuItems={categoryOptions} column={categoryColumn} label="Kategori" />
          ) : null}
          {subCategoryColumn ? (
            <SelectFilter menuItems={subCategoryOptions} column={subCategoryColumn} label="Underkategori" />
          ) : null}
        </Grid2>
      </Box>
    )
  );
};

export default MRPStockOverviewFilter;
