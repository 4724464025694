import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, Divider,
  IconButton, Typography, Box,
} from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Delete, Edit, Info, Refresh,
} from '@mui/icons-material';
import { monitorApi } from '../../../apis/apis.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import NotificationChannelForm from '../../monitor/notificationChannel/NotificationChannelForm.js';
import { MonitorQueries } from '../../../queries/index.js';
import type { NotificationChannelsResponseInner } from '../../../../generated/activityhelper/api.js';
import GenericTable from '../../table/GenericTable.js';
import { MonitorMutations } from '../../../mutations/index.js';
import DialogCloseButton from '../../common/dialogs/DialogCloseButton.js';
import NotificationChannelDialogContent from '../../monitor/notificationChannel/NotificationChannelDialogContent.js';
import NotificationChannelEditDialogContent from '../../monitor/notificationChannel/NotificationChannelEditDialogContent.js';

const channelColumnHelper = createColumnHelper<NotificationChannelsResponseInner>();

const channelColumns = [
  // type, value, rateLimit
  channelColumnHelper.accessor((r) => r.type, {
    header: 'Type',
    id: 'type',
  }),
  channelColumnHelper.accessor((r) => r.value, {
    header: 'Value',
    id: 'value',
  }),
  channelColumnHelper.accessor((r) => r.rateLimit, {
    header: 'Rate Limit (sec)',
    id: 'rateLimit',
  }),
  channelColumnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (info) => (
      <>
        <IconButton color="primary" onClick={() => info.table.options.meta?.openRowDialog(info)}>
          <Info />
        </IconButton>
        <IconButton color="success" onClick={() => info.table.options.meta?.openEditDialog(info)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => info.table.options.meta?.openDeleteDialog(info)}>
          <Delete />
        </IconButton>
      </>
    ),
  }),
];

const MonitorAlertingAdminPage = () => {
  const queryClient = useQueryClient();
  const [dialogAddOpen, setDialogAddOpen] = React.useState(false);
  const channels = useQuery(MonitorQueries.notificationChannelsQuery);
  const deleteNotif = useMutation(MonitorMutations.deleteNotificationChannelMutation);
  const mutation = useMutation({
    mutationFn: async () => {
      await monitorApi.monitorAlertSyncPolicyPost({});
    },
  });

  return (
    <Box>
      <MonitorPageTitle title="Alerting Administration" />
      <Typography variant="h6">Sync Google alert policies to the local db</Typography>
      <LoadingButton customIcon={<Refresh />} buttonProps={{ color: 'warning', loading: mutation.isPending, onClick: () => mutation.mutate() }}>Sync</LoadingButton>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>Notification channels:</Typography>
      <Button onClick={() => setDialogAddOpen(true)} variant="contained" sx={{ mb: 2 }}>Add new channel</Button>
      <GenericTable
        id="notificationChannels"
        columns={channelColumns}
        data={channels.data ?? []}
        pagination
        rowDialogProps={{ title: 'Notification Channel', maxWidth: 'md', Content: NotificationChannelDialogContent }}
        deleteDialogProps={{
          title: 'Delete notification channel?',
          onDelete: async (row, close) => {
            if (row) {
              await deleteNotif.mutateAsync({ id: row.id.toString() });
              queryClient.invalidateQueries(MonitorQueries.notificationChannelsQuery);
              close();
            }
          },
        }}
        editDialogProps={{
          Content: NotificationChannelEditDialogContent,
          title: 'Edit notification channel',
          maxWidth: 'sm',
        }}
        keyExtractor={(row) => row.id}
      />
      <Dialog open={dialogAddOpen} onClose={() => setDialogAddOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          Add new notification channel
        </DialogTitle>
        <DialogCloseButton handleClose={() => setDialogAddOpen(false)} />
        <DialogContent>
          <NotificationChannelForm handleClose={() => setDialogAddOpen(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonitorAlertingAdminPage;
