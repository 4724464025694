import React, { useMemo } from 'react';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Tooltip, Typography, Box } from '@mui/material';
import { DateUtils } from '../../../sharedutils/index.js';
import type { HourReport } from '../../types.js';
import GenericTable from '../table/GenericTable.js';
import HourReportTableActions from './HourReportTableActions.js';

const columnHelper = createColumnHelper<HourReport>();

const emailColumn = [
  columnHelper.accessor((row) => row.email, {
    header: 'Epost',
    cell: (info) => info.getValue(),
    id: 'email',
  }),
] as ColumnDef<HourReport>[];

const commonColumns = [
  columnHelper.accessor((row) => row.date, {
    header: 'Dato',
    cell: (info) => {
      const created = new Date(info.row.original.created);
      const thisDate = new Date(info.getValue());
      const sameDate = DateUtils.compareDates(created, thisDate);
      if (!sameDate) {
        return (
          (
            <Tooltip title={`Dato lagra og data oppretta var ulikt! Dato her skulle ha vert: ${DateUtils.getLocalizedDate(created)}`}>
              <Typography sx={{
                color: 'yellow',
              }}
              >
                {DateUtils.getLocalizedDate(thisDate)}
              </Typography>
            </Tooltip>
          )
        );
      }
      return DateUtils.getLocalizedDate(info.getValue());
    },
    id: 'date',
  }),
  // TODO Mark this (and/or date) as red/"error" if created and date are NOT the same date!!!
  columnHelper.accessor((row) => row.created, {
    header: 'Tidspunkt',
    cell: (info) => DateUtils.getLocalizedTime(info.getValue()),
    id: 'timestamp',
  }),
  columnHelper.accessor((row) => row.type, {
    header: 'Type',
    cell: (info) => info.getValue(),
    id: 'type',
  }),
  columnHelper.accessor((row) => row.absenceType, {
    header: 'Egenmelding-/fraværstype',
    cell: (info) => info.getValue(),
    id: 'absenceType',
  }),
  // TODO Tooltip on the type above???
  // columnHelper.accessor((row) => row.childBirthdate, {
  //   header: 'Barnets fødselsdato',
  //   cell: (info) => info.getValue(),
  //   id: 'childBirthdate',
  // }),
  columnHelper.accessor((row) => row.from, {
    header: 'Fra',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
    id: 'from',
  }),
  columnHelper.accessor((row) => row.to, {
    header: 'Til',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
    id: 'to',
  }),
  columnHelper.accessor((row) => row.notes, {
    header: 'Notater',
    cell: (info) => info.getValue(),
    id: 'notes',
  }),
  columnHelper.accessor((row) => row.finished, {
    header: 'Ferdig',
    cell: (info) => (info.getValue() === '1' ? 'Ja' : 'Nei'),
    id: 'finished',
  }),
] as ColumnDef<HourReport>[];

type HourReportTableProps = {
  id: string,
  data: HourReport[],
  includeEmail?: boolean,
  csvActions?: boolean,
};

const HourReportTable = (props: HourReportTableProps) => {
  const {
    id, data, includeEmail, csvActions,
  } = props;
  const initialSortingState = useMemo<SortingState>(() => [{ id: 'date', desc: true }], []);
  const columns = useMemo(() => (includeEmail ? [...emailColumn, ...commonColumns] : commonColumns), [includeEmail]);

  return (
    <Box>
      <GenericTable
        id={id}
        columns={columns}
        data={data}
        pagination
        initialSortingState={initialSortingState}
        Filter={csvActions ? HourReportTableActions : null}
      />
    </Box>
  );
};

export default HourReportTable;
