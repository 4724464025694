import { Box, TextField } from '@mui/material';
import type { Table } from '@tanstack/react-table';
import React, { useCallback, useEffect, useState } from 'react';

type DebouncedFilterProps<T> = {
  table: Table<T>,
  label: string,
  minWidth?: number,
  type?: React.HTMLInputTypeAttribute,
  debounce?: number,
  initialValue?: string | number,
};

const DebouncedGlobalFilter = <T, >(props: DebouncedFilterProps<T>) => {
  const {
    label, table, minWidth, type, debounce,
    initialValue,
  } = props;
  const [value, setValue] = useState<string | number>(initialValue ?? '');

  const onChange = useCallback((val: string | number) => {
    table.setGlobalFilter(val);
  }, [table]);

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce ?? 500);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <Box>
      <TextField
        type={type ?? 'text'}
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        sx={{ minWidth: minWidth ?? 250 }}
      />
    </Box>
  );
};

export default DebouncedGlobalFilter;
