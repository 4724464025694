import {
  Box, Grid2, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import LoadingButton from '../common/buttons/LoadingButton.js';
import { GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum } from '../../../generated/activityhelper/api.js';

type ERPSearchProps = {
  onSearch: (search: { orderId: string, orderIdType: GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum }) => (void | Promise<void>),
  isLoading: boolean,
};

const ERPSearch = (props: ERPSearchProps) => {
  const { onSearch, isLoading } = props;
  const [orderId, setOrderId] = React.useState('');
  const [orderType, setOrderType] = React.useState<GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum>(
    GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum.Cppro,
  );

  const onClick = useCallback(() => {
    onSearch({ orderId, orderIdType: orderType });
  }, [onSearch, orderId, orderType]);

  console.log('ok');
  return (
    <Box>
      <Typography gutterBottom>Søk etter ordre:</Typography>
      <Grid2
        container
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Grid2>
          <Select
            value={orderType}
            onChange={(e) => setOrderType(e.target.value as GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum)}
            title="Ordretype"
            label="Ordretype"
          >
            {Object.values(GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum).map((o) => (<MenuItem key={o} value={o}>{o}</MenuItem>))}
          </Select>
        </Grid2>
        <Grid2>
          <TextField
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            title="Ordre ID"
            label="Ordre ID"
          />
        </Grid2>
        <Grid2>
          <LoadingButton buttonProps={{ variant: 'contained', onClick, loading: isLoading }}>Søk</LoadingButton>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ERPSearch;
