import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Divider, Typography } from '@mui/material';
import { ERPQueries } from '../../../queries/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import ERPSearch from '../../erp/ERPSearch.js';
import GenericTable from '../../table/GenericTable.js';
import type { GetErpErrorResponseErpErrorsInner, GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum } from '../../../../generated/activityhelper/api.js';

const columnHelper = createColumnHelper<GetErpErrorResponseErpErrorsInner>();

const columns = [
  columnHelper.accessor((row) => row.orderId, {
    header: 'Order ID',
    id: 'orderId',
  }),
  columnHelper.accessor((row) => row.orderIdType, {
    header: 'Order ID Type',
    id: 'orderIdType',
  }),
  // add accessor for these: type, message, cause, errorStack, errorCode, apiUrl, requestUrl, taskId, timestamp, metadata
  columnHelper.accessor((row) => row.type, {
    header: 'Type',
    id: 'type',
  }),
  columnHelper.accessor((row) => row.message, {
    header: 'Message',
    id: 'message',
  }),
  columnHelper.accessor((row) => row.cause, {
    header: 'Cause',
    id: 'cause',
  }),
  columnHelper.accessor((row) => row.errorStack, {
    header: 'Error Stack',
    id: 'errorStack',
  }),
  columnHelper.accessor((row) => row.errorCode, {
    header: 'Error Code',
    id: 'errorCode',
  }),
  columnHelper.accessor((row) => row.apiUrl, {
    header: 'Api Url',
    id: 'apiUrl',
  }),
  columnHelper.accessor((row) => row.requestUrl, {
    header: 'Request Url',
    id: 'requestUrl',
  }),
  columnHelper.accessor((row) => row.taskId, {
    header: 'Task ID',
    id: 'taskId',
  }),
  columnHelper.accessor((row) => row.timestamp, {
    header: 'Timestamp',
    id: 'timestamp',
  }),
  columnHelper.accessor((row) => row.metadata, {
    header: 'Metadata',
    id: 'metadata',
    cell: (info) => JSON.stringify(info.getValue() ?? {}), // TODO Show in modal?
  }),
];

const initialSortingState: SortingState = [{ id: 'timestamp', desc: true }];

const ERPErrors = () => {
  const [query, setQuery] = React.useState(ERPQueries.getErpErrorsQuery({}));
  const { data, isLoading } = useQuery(query);

  const onSearch = useCallback((search: { orderId: string, orderIdType: GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum }) => {
    // eslint-disable-next-line default-case
    switch (search.orderIdType) {
      case 'AC':
        setQuery(ERPQueries.getErpErrorsQuery({ acId: search.orderId, isACT: false }));
        break;
      case 'ACT':
        setQuery(ERPQueries.getErpErrorsQuery({ acId: search.orderId, isACT: true }));
        break;
      case 'CPPRO':
        setQuery(ERPQueries.getErpErrorsQuery({ cpproId: search.orderId }));
        break;
      case 'EXTRA_AC':
        setQuery(ERPQueries.getErpErrorsQuery({ extraACOrder: search.orderId }));
        break;
      case 'FJ':
        setQuery(ERPQueries.getErpErrorsQuery({ fjId: search.orderId }));
        break;
      case 'FJ_MANUAL':
        setQuery(ERPQueries.getErpErrorsQuery({ fjManualOrder: search.orderId }));
        break;
      case 'MRP':
        setQuery(ERPQueries.getErpErrorsQuery({ mrpId: search.orderId }));
        break;
    }
  }, []);

  return (
    <Box>
      <MonitorPageTitle title="ERP Errors" />
      <ERPSearch onSearch={onSearch} isLoading={isLoading} />
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
      <Typography variant="h5" gutterBottom>Errors</Typography>
      <GenericTable data={data ?? []} columns={columns} id="erpErrors" pagination initialSortingState={initialSortingState} />
    </Box>
  );
};

export default ERPErrors;
