import type { Theme } from '@mui/material';
import {
  List, ListItem, ListItemIcon, ListItemText, Typography, useTheme, Box,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { KeyboardArrowRight } from '@mui/icons-material';
import { MonitorQueries } from '../../../queries/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import MonitorLogsSearch from '../../monitor/search/MonitorLogsSearch.js';
import { MonitorTypes } from '../../monitor/index.js';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import { DateUtils } from '../../../../sharedutils/index.js';

declare module 'react' {
  interface CSSProperties {
    '--statuscolor': string;
  }
}

const getStatusColor = (code: number, theme: Theme) => {
  let color = 'inherit';
  if (code < 400) color = theme.palette.success.main;
  else if (code < 500) color = theme.palette.warning.light;
  else color = theme.palette.error.dark;
  return { color };
};

const MonitorLeadPage = () => {
  const theme = useTheme();
  const [filters, setFilters] = useState<Required<MonitorTypes.LogEntriesCommand>>(MonitorTypes.logDefaultData);
  const {
    queryData: leadRequestsData, query: leadRequestsQuery,
    refetch: refetchLead,
  } = useSearchQuery(MonitorQueries.getLeadRequestsQuery, MonitorTypes.logFixedDefaultData);
  const {
    queryData: leadVendorsData, query: leadVendorsQuery,
    refetch: refetchVendors,
  } = useSearchQuery(MonitorQueries.getLeadVendorsQuery, MonitorTypes.logFixedDefaultData);
  // TODO Recreate the only fetch query when search button is pressed on filters (see hour report(?) / sales overview!!)
  // Make this some kind of reusable hook?
  const { data: leadRequests, isLoading: requestsIsLoading } = leadRequestsData;
  const { data: leadVendors, isLoading: vendorsIsLoading } = leadVendorsData;
  const loading = requestsIsLoading || vendorsIsLoading;

  const onSearch = useCallback(() => {
    const fixedFilters = {
      ...filters,
      ...DateUtils.fixDateCommand(filters),
    };

    refetchLead(fixedFilters);
    refetchVendors(fixedFilters);
  }, [filters, refetchLead, refetchVendors]);

  return (
    <Box>
      <MonitorPageTitle title="Lead API" refreshQuery={[leadRequestsQuery, leadVendorsQuery]} />
      <Box>
        <MonitorLogsSearch
          state={filters}
          setState={setFilters}
          onSearch={onSearch}
          loading={loading}
        />
      </Box>
      <Typography variant="h6">
        Lead request statuscodes:
      </Typography>
      <List>
        {leadRequests ? leadRequests.map((req) => (
          <ListItem key={req.code}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
              <KeyboardArrowRight />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: 'var(--statuscolor)',
              }}
              style={{ '--statuscolor': getStatusColor(req.code, theme).color }}
              primary={(
                <>
                  {`${req.code} ${req.status}:`}
                  &nbsp;
                  <b>{req.count}</b>
                </>
            )}
              secondary={req.statusMessage}
            />
          </ListItem>
        )) : null}

      </List>
      <Typography variant="h6">
        Lead vendors API usage (successful requests):
      </Typography>
      <List>
        {leadVendors ? Object.entries(leadVendors).map(([key, value]) => (
          <ListItem key={key}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
              <KeyboardArrowRight />
            </ListItemIcon>
            <ListItemText primary={`${key}: ${value}`} />
          </ListItem>
        )) : null}
      </List>
    </Box>
  );
};

export default MonitorLeadPage;
