import {
  IconButton, Tooltip, Typography, Box,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import { DeleteForever, Edit, Info } from '@mui/icons-material';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import { MonitorQueries } from '../../../queries/index.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import GenericTable from '../../table/GenericTable.js';
import MonitorIncidentTableFilter from '../../monitor/incident/MonitorIncidentTableFilter.js';
import type { AlertPolicyIncidentsResponseInner, GetIncidentsCommand, LogEntriesResponseInner } from '../../../../generated/activityhelper/api.js';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import MonitorIncidentSearch from '../../monitor/incident/MonitorIncidentSearch.js';
import { MonitorMutations } from '../../../mutations/index.js';

type IncidentRow = AlertPolicyIncidentsResponseInner & { policyName: string | undefined };

const columnHelper = createColumnHelper<IncidentRow>();

const incidentColumns = [
  columnHelper.accessor((r) => r.date, {
    header: 'Date',
    id: 'date',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((r) => r.status, {
    header: 'Status',
    id: 'status',
  }),
  columnHelper.accessor((r) => r.policyName, {
    header: 'Policy Name',
    id: 'policyName',
  }),
  columnHelper.accessor((r) => r.summary, {
    header: 'Summary',
    id: 'summary',
  }),
  columnHelper.accessor((r) => r.incidentUrl, {
    header: 'Incident URL',
    id: 'incidentUrl',
    cell: (info) => (<a href={info.getValue()}>{info.row.original.incidentId}</a>),
  }),
  columnHelper.accessor((r) => r.serviceName, {
    header: 'Service Name',
    id: 'serviceName',
  }),
  columnHelper.accessor((r) => r.severity, {
    header: 'Severity',
    id: 'severity',
  }),
  columnHelper.accessor((r) => r.notified, {
    header: 'Notified Date',
    id: 'notified',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (info) => (
      <>
        <Tooltip title="Show details">
          <IconButton color="primary" onClick={() => info.table.options.meta?.openRowDialog(info)}>
            <Info />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton color="success" onClick={() => info.table.options.meta?.openEditDialog(info)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => info.table.options.meta?.openDeleteDialog(info)}>
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </>
    ),
  }),
];

const logColumnHelper = createColumnHelper<LogEntriesResponseInner>();

const logColumns = [
  // timestamp (date cell), message, severity
  logColumnHelper.accessor((r) => r.timestamp, {
    header: 'Timestamp',
    id: 'timestamp',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  logColumnHelper.accessor((r) => r.message, {
    header: 'Message',
    id: 'message',
  }),
  logColumnHelper.accessor((r) => r.severity, {
    header: 'Severity',
    id: 'severity',
  }),
  logColumnHelper.accessor((r) => r.data?.metadata, {
    header: 'Metadata',
    id: 'metadata',
    cell: (info) => {
      const data = info.getValue();
      if (!data) return 'No metadata';
      return <pre><code>{JSON.stringify(data, undefined, 2)}</code></pre>;
    },
  }),
];

const DialogContent = (props: { row: IncidentRow | undefined }) => {
  const { row } = props;
  if (!row) return null;
  const { logs } = row;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Details:</Typography>
      <Typography>
        <b>Date:</b>
        {' '}
        {DateUtils.getLocalizedDateTime(row.date)}
      </Typography>
      <Typography>
        <b>Status:</b>
        {' '}
        {row.status}
      </Typography>
      <Typography>
        <b>Policy Name:</b>
        {' '}
        {row.policyName}
      </Typography>
      <Typography>
        <b>Incident URL:</b>
        {' '}
        <a href={row.incidentUrl}>{row.incidentId}</a>
      </Typography>
      <Typography>
        <b>Service Name:</b>
        {' '}
        {row.serviceName}
      </Typography>
      <Typography>
        <b>Log filter:</b>
        {' '}
        {row.logFilter}
      </Typography>
      <Typography>
        <b>Severity:</b>
        {' '}
        {row.severity}
      </Typography>
      <Typography>
        <b>Notified Date:</b>
        {' '}
        {DateUtils.getLocalizedDateTime(row.notified as string)}
      </Typography>
      <Typography variant="h6" gutterBottom>Documentation:</Typography>
      {row.documentation ? (<pre><code>{row.documentation}</code></pre>) : <Typography>No documentation</Typography>}
      <Typography variant="h6" gutterBottom>Logs:</Typography>
      {logs ? (
        <GenericTable id="logs" columns={logColumns} data={(logs ?? []) as LogEntriesResponseInner[]} />
      ) : <Typography>No logs</Typography>}
    </Box>
  );
};

const defaultData: GetIncidentsCommand = {
  startTime: DateUtils.addDateDays(new Date(), -1).toISOString(),
  endTime: new Date().toISOString(),
};

const MonitorIncidentPage = () => {
  const { id } = useParams();

  const queryClient = useQueryClient();

  const { queryData: incidents, refetch, query } = useSearchQuery(MonitorQueries.getIncidentsQuery, defaultData);
  const policies = useQuery(MonitorQueries.policiesQuery);

  const deleteIncident = useMutation(MonitorMutations.deleteAlertPolicyIncident);

  const initialSortingState = useMemo<SortingState>(() => [{ id: 'date', desc: true }], []);

  const incidentsWithPolicies = useMemo(() => {
    if (incidents.data && policies.data) {
      return incidents.data.map((incident) => ({
        ...incident,
        policyName: policies.data.find((policy) => policy.id === incident.alertPolicyId)?.displayName,
      }));
    }
    return [];
  }, [incidents.data, policies.data]);

  const initialRowFinder = useMemo(() => {
    if (!id || Number.isNaN(Number(id))) return undefined;
    return (row: IncidentRow) => row.id === Number(id);
  }, [id]);

  return (
    <Box>
      <MonitorPageTitle title="Incidents" refreshQuery={[MonitorQueries.incidentsQuery, MonitorQueries.policiesQuery]} />
      <MonitorIncidentSearch defaultData={defaultData} onSearch={refetch} loading={incidents.isLoading} />
      <Typography variant="h6" gutterBottom>Incidents</Typography>
      <GenericTable
        id="incidents"
        columns={incidentColumns}
        data={incidentsWithPolicies}
        initialSortingState={initialSortingState}
        pagination
        Filter={MonitorIncidentTableFilter}
        rowDialogProps={{
          title: 'Incident details',
          Content: DialogContent,
          maxWidth: 'xl',
          initialRowFinder,
        }}
        deleteDialogProps={{
          title: 'Delete incident?',
          onDelete: async (row, close) => {
            if (row) {
              await deleteIncident.mutateAsync(row.id);
              queryClient.invalidateQueries(query);
              close();
            }
          },
        }}
      />
    </Box>
  );
};

export default MonitorIncidentPage;
