import { Button, Grid2 } from '@mui/material';
import React, { useCallback } from 'react';
import MonitorDateSearch from './MonitorDateSearch.js';
import type { MonitorTypes } from '../index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

type MonitorLogsSearchProps = {
  state: Required<MonitorTypes.LogEntriesCommand>,
  setState: React.Dispatch<React.SetStateAction<Required<MonitorTypes.LogEntriesCommand>>>,
  onSearch: () => void,
  loading?: boolean,
};

const MonitorLogsSearch = (props: MonitorLogsSearchProps) => {
  const {
    state, setState, onSearch, loading,
  } = props;

  const setStart = useCallback((start: string) => setState({ ...state, startTime: start }), [setState, state]);
  const setEnd = useCallback((end: string) => setState({ ...state, endTime: end }), [setState, state]);

  return (
    (
      <Grid2
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <MonitorDateSearch start={state.startTime} end={state.endTime} setStart={setStart} setEnd={setEnd} />
        <Grid2>
          {loading !== undefined ? (
            <LoadingButton
              buttonProps={{
                loading,
                onClick: onSearch,
              }}
            >
              <span>Search</span>
            </LoadingButton>
          ) : (
            <Button variant="contained" onClick={onSearch}>Search</Button>
          )}
        </Grid2>
      </Grid2>
    )
  );
};

export default MonitorLogsSearch;
