import { TextField, Box } from '@mui/material';
import type {
  Column, Row,
} from '@tanstack/react-table';
import React from 'react';

type DateRangeFilterProps<T> = {
  column: Column<T>,
  startLabel?: string,
  endLabel?: string,
};

type DateRangeProps = {
  valueStart: string | undefined,
  onChangeStart: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  valueEnd: string | undefined,
  onChangeEnd: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  datetime?: boolean,
  startLabel?: string,
  endLabel?: string,
};

function resetDateToDay(date: Date): Date;
function resetDateToDay(date: undefined | null): undefined | null;
function resetDateToDay(date: Date | undefined | null): Date | undefined | null {
  if (!date) return date;
  const copy = new Date(date);
  copy.setHours(0, 0, 0, 0);
  return copy;
}

// From https://github.com/TanStack/table/discussions/4284#discussioncomment-3409953
// With TypeScript modifications and for our usecase
export const isWithinDateRangeFilter = <T,>(row: Row<T>, columnId: string, filterValue: [string, string]): boolean => {
  const date = resetDateToDay(row.getValue(columnId) as Date);
  const start = filterValue[0] ? resetDateToDay(new Date(filterValue[0])) : undefined;
  const end = filterValue[1] ? resetDateToDay(new Date(filterValue[1])) : undefined;

  if ((start || end) && !date) return false;
  if (start && !end) return date.getTime() >= start.getTime();
  if (end && !start) return date.getTime() <= end.getTime();
  if (!end || !start) return true;
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
};

export const DateRange = (props: DateRangeProps) => {
  const {
    valueStart, onChangeStart, valueEnd, onChangeEnd, datetime,
    startLabel, endLabel,
  } = props;
  return (
    <>
      <TextField
        label={startLabel ?? 'Start'}
        variant="filled"
        type={datetime ? 'datetime-local' : 'date'}
        value={valueStart ?? ''}
        onChange={onChangeStart}
        sx={{ marginRight: 2 }}
        slotProps={{
          inputLabel: { shrink: true },
        }}
      />
      <TextField
        label={endLabel ?? 'Slutt'}
        variant="filled"
        type={datetime ? 'datetime-local' : 'date'}
        value={valueEnd ?? ''}
        onChange={onChangeEnd}
        slotProps={{
          inputLabel: { shrink: true },
        }}
      />
    </>
  );
};

const DateRangeFilter = <T,>(props: DateRangeFilterProps<T>) => {
  const { column, startLabel, endLabel } = props;
  const columnFilterValue = column.getFilterValue();

  return (
    <Box>
      <DateRange
        valueStart={(columnFilterValue as [string, string])?.[0]}
        onChangeStart={(e) => column.setFilterValue((old: [string, string]) => [e.target.value, old?.[1]])}
        valueEnd={(columnFilterValue as [string, string])?.[1]}
        onChangeEnd={(e) => column.setFilterValue((old: [string, string]) => [old?.[0], e.target.value])}
        startLabel={startLabel}
        endLabel={endLabel}
      />
    </Box>
  );
};

export default DateRangeFilter;
