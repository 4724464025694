import { Menu } from '@mui/icons-material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material';
import {
  AppBar as MuiAppBar, IconButton, Toolbar, styled, Typography,
} from '@mui/material';
import React from 'react';

type MonitoringHeaderProps = {
  open: boolean;
  handleOpen: () => void;
};

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MonitorHeader = ({ open, handleOpen }: MonitoringHeaderProps) => (
  <AppBar position="fixed" open={open}>
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleOpen}
        edge="start"
        sx={[{
          marginRight: 5,
        }, open && { display: 'none' }]}
      >
        <Menu />
      </IconButton>
      <Typography variant="h6" noWrap component="div">
        Monitoring
      </Typography>
    </Toolbar>
  </AppBar>
);

export default MonitorHeader;
