import React, { useMemo } from 'react';
import {
  Button, Typography, Box, Grid2,
} from '@mui/material';
import type { GenericTableFilterProps } from '../table/GenericTable.js';
import DateRangeFilter from '../table/DateRangeFilter.js';
import type { SalesRow } from '../../types.js';
import { saleTypes, statusToString } from '../../types.js';
import SelectFilter from '../table/SelectFilter.js';
import AutocompleteFilter from '../table/filters/AutocompleteFilter.js';
import { CSVUtils } from '../../utils/index.js';

const SalesOverviewFilter = (props: GenericTableFilterProps<SalesRow>) => {
  const { table, tableId } = props;

  const dateColumn = table.getColumn('date');
  const statusColumn = table.getColumn('status');
  const adviserColumn = table.getColumn('adviser');

  const statusMenuItems = useMemo(() => saleTypes.map((s) => ({ label: statusToString(s), value: s })), []);

  return (
    (
      <Box sx={{ padding: 1 }}>
        <Typography
          gutterBottom
          sx={{
            fontWeight: 'bold',
          }}
        >
          Filter:
        </Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
          }}
        >
          {dateColumn ? (
            <Grid2>
              <DateRangeFilter column={dateColumn} />
            </Grid2>
          ) : null}
          {statusColumn ? (
            <Grid2>
              <SelectFilter menuItems={statusMenuItems} column={statusColumn} label="Status" />
            </Grid2>
          ) : null}
          {adviserColumn ? (
            <Grid2>
              <AutocompleteFilter column={adviserColumn} label="Rådgiver" />
            </Grid2>
          ) : null}
          {tableId ? (
            <>
              <Grid2>
                <Button variant="contained" onClick={() => CSVUtils.downloadTableAsCSV(table, 'sales.csv', 'OrdreID', true)}>
                  Last ned som CSV-fil
                </Button>
              </Grid2>
              {/* <Grid2>
              <Button variant="contained" onClick={() => CopyUtils.copyTableToClipboard(tableId, 'body')}>
                Kopier synlig tabellinnhold
              </Button>
            </Grid2> */}
              <Grid2>
                <Button variant="contained" onClick={() => CSVUtils.copyTableToClipboardAsCSV(table, 'OrdreID', true)}>
                  Kopier filtrert tabell som CSV
                </Button>
              </Grid2>
            </>
          ) : null}
        </Grid2>
      </Box>
    )
  );
};

export default SalesOverviewFilter;
