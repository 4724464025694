import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button, Checkbox, Divider, FormControlLabel, Link, List, ListItem,
  ListItemIcon, ListItemText, Paper, TextField, Tooltip, Typography,
  Box, Stack, Grid2,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ExpandMore, HorizontalRule } from '@mui/icons-material';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import ConfirmDialog from '../../common/dialogs/ConfirmDialog.js';
import { ERPQueries } from '../../../queries/index.js';
import type { GetErpSyncOrderCommand, GetErpSyncOrderResponse, MrpPickListInner } from '../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import { ERPMutations } from '../../../mutations/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

const ERPInvoicing = () => {
  const [saleName, setSaleName] = useState('');
  const [installName, setInstallName] = useState('');
  const [saleId, setSaleId] = useState<string>('');
  const [installId, setInstallId] = useState<string>('');
  const [acErpId, setACErpId] = useState<string>('');
  const [fjErpId, setFjErpId] = useState<string>('');

  const [invoiceId, setInvoiceId] = useState<string>('');
  const [invoiceDate, setInvoiceDate] = useState<string>('');

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const queryClient = useQueryClient();

  const [ranInvoicingProcess, setRanInvoicingProcess] = useState(false);

  const [installCompleteDryRun, setInstallCompleteDryRun] = useState(true);
  const [mrpPickDryRun, setMrpPickDryRun] = useState(true);
  const [invoiceAndInactivateDryRun, setInvoiceAndInactivateDryRun] = useState(true);

  const [installCompleteEnableDelete, setInstallCompleteEnableDelete] = useState(false);

  const installCompleteMutation = useMutation(ERPMutations.checklistInstalledMutation);
  const acInvoiceMutation = useMutation(ERPMutations.acInvoiceOrderMutation);
  const mrpPickMutation = useMutation(ERPMutations.mrpPickOrderMutation);
  const mrpShipMutation = useMutation(ERPMutations.mrpShipOrderMutation);
  const mrpInvoiceMutation = useMutation(ERPMutations.mrpInvoiceOrderMutation);
  const invoiceAndInactivateMutation = useMutation(ERPMutations.inactivateFJAndInvoiceACOrderMutation);
  const mrpManuallyInvoicedMutation = useMutation(ERPMutations.mrpManuallyInvoicedMutation);
  const mrpFindMRPOrderMutation = useMutation(ERPMutations.mrpFindMRPOrderMutation);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [onActionConfirm, setOnActionConfirm] = useState(() => () => {});

  const [erpSyncCommand, setErpSyncCommand] = useState<GetErpSyncOrderCommand | undefined>(undefined);
  const erpSyncQuery = useMemo(() => ERPQueries.getErpSyncOrderQuery(erpSyncCommand), [erpSyncCommand]);
  const { data: erpSync, isLoading } = useQuery(erpSyncQuery);

  const onSearch = useCallback(() => {
    if (saleName) {
      setErpSyncCommand({
        saleOrder: saleName,
      });
    } else if (installName) {
      setErpSyncCommand({
        installOrder: installName,
      });
    } else if (saleId) {
      setErpSyncCommand({
        saleOrderId: saleId,
      });
    } else if (installId) {
      setErpSyncCommand({
        installorderId: installId,
      });
    } else if (acErpId) {
      setErpSyncCommand({
        acErpId,
      });
    } else if (fjErpId) {
      setErpSyncCommand({
        fjErpId,
      });
    }
    installCompleteMutation.reset();
    acInvoiceMutation.reset();
    mrpPickMutation.reset();
    mrpInvoiceMutation.reset();
    invoiceAndInactivateMutation.reset();
    mrpManuallyInvoicedMutation.reset();
    mrpFindMRPOrderMutation.reset();
    setInvoiceId('');
    setInvoiceDate('');
    setRanInvoicingProcess(false);
  }, [saleName, installName, saleId, installId, acErpId, fjErpId, installCompleteMutation,
    acInvoiceMutation, mrpPickMutation, mrpInvoiceMutation, invoiceAndInactivateMutation,
    mrpManuallyInvoicedMutation, mrpFindMRPOrderMutation]);

  const onConfirm = React.useCallback(() => {
    onActionConfirm();
    setConfirmOpen(false);
  }, [onActionConfirm]);

  const onUpdateOrderButtonClick = (erpSyncData: GetErpSyncOrderResponse) => {
    if (erpSyncData?.cpproInstallId && erpSyncData.cpproInstallId !== null) {
      if (!installCompleteDryRun || (!installCompleteDryRun && installCompleteEnableDelete)) {
        setOnActionConfirm(() => () => {
          installCompleteMutation.mutate(
            { installCPproId: erpSyncData.cpproInstallId!.toString(), enableDelete: installCompleteEnableDelete ? 'true' : undefined },
            { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
          );
        });
        setConfirmOpen(true);
      } else {
        installCompleteMutation.mutate({ installCPproId: erpSyncData.cpproInstallId!.toString(), dryRun: 'true' });
      }
    }
  };

  const onSetACInvoicedButtonClick = (erpSyncData: GetErpSyncOrderResponse) => {
    acInvoiceMutation.mutate(
      { cpproId: erpSyncData.cpproId.toString() },
      { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
    );
  };

  const onMrpPickButtonClick = (erpSyncData: GetErpSyncOrderResponse) => {
    if (!mrpPickDryRun) {
      setOnActionConfirm(() => () => {
        mrpPickMutation.mutate(
          { cpproId: erpSyncData.cpproId.toString() },
          { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
        );
      });
      setConfirmOpen(true);
    } else {
      mrpPickMutation.mutate({ cpproId: erpSyncData.cpproId.toString(), dryRun: 'true' });
    }
  };

  const onMrpShipButtonClick = (erpSyncData: GetErpSyncOrderResponse) => {
    mrpShipMutation.mutate(
      { cpproId: erpSyncData.cpproId.toString() },
      { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
    );
  };

  const onMrpInvoiceButtonClick = (erpSyncData: GetErpSyncOrderResponse) => {
    mrpInvoiceMutation.mutate(
      { cpproId: erpSyncData.cpproId.toString() },
      { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
    );
  };

  const onInvoiceAndInactivateButtonClick = (erpSyncData: GetErpSyncOrderResponse) => {
    if (!invoiceAndInactivateDryRun) {
      setOnActionConfirm(() => () => {
        invoiceAndInactivateMutation.mutate(
          { cpproId: erpSyncData.cpproId.toString() },
          { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
        );
      });
      setConfirmOpen(true);
    } else {
      invoiceAndInactivateMutation.mutate({ cpproId: erpSyncData.cpproId.toString(), dryRun: 'true' });
    }
  };

  const erpSyncOnlyAC = erpSync?.acErpId && !erpSync.fjErpId && !erpSync.mrpSaleId;

  return (
    (
      <Box>
        <MonitorPageTitle title="24SO / Masterplan Fakturering" />
        <Box>
          <Typography gutterBottom>Søk etter ordre (navn):</Typography>
          <Grid2
            container
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Grid2>
              <TextField
                value={saleName}
                onChange={(e) => setSaleName(e.target.value)}
                title="Salgsordre"
                label="Salgsordre"
                disabled={!!installName || !!saleId || !!installId || !!acErpId || !!fjErpId}
              />

            </Grid2>
            <Grid2>
              <TextField
                value={installName}
                onChange={(e) => setInstallName(e.target.value)}
                title="Monteringsordre"
                label="Monteringsordre"
                disabled={!!saleName || !!saleId || !!installId || !!acErpId || !!fjErpId}
              />
            </Grid2>
            <Grid2>
              <TextField
                value={acErpId}
                onChange={(e) => setACErpId(e.target.value)}
                title="24SO AC(T) ID"
                label="24SO AC(T) ID"
                disabled={!!installName || !!installId || !!saleName || !!saleId || !!fjErpId}
              />
            </Grid2>
            <Grid2>
              <TextField
                value={fjErpId}
                onChange={(e) => setFjErpId(e.target.value)}
                title="24SO FJ Klima Hjelpeordre ID"
                label="24SO FJ Klima Hjelpeordre ID"
                disabled={!!installName || !!installId || !!saleName || !!saleId || !!acErpId}
              />
            </Grid2>
            <Grid2>
              <FormControlLabel label="Avansert søk" id="advancedLabel" control={<Checkbox value={advancedSearch} onChange={(_, checked) => setAdvancedSearch(checked)} />} />
            </Grid2>
            {advancedSearch ? (
              <>
                <Grid2>
                  <TextField
                    value={saleId}
                    onChange={(e) => setSaleId(e.target.value)}
                    title="Salgsordre ID"
                    label="Salgsordre ID"
                    disabled={!!installName || !!installId || !!saleName || !!acErpId || !!fjErpId}
                  />
                </Grid2>
                <Grid2>
                  <TextField
                    value={installId}
                    onChange={(e) => setInstallId(e.target.value)}
                    title="Monteringsordre ID"
                    label="Monteringsordre ID"
                    disabled={!!saleName || !!saleId || !!installName || !!acErpId || !!fjErpId}
                  />
                </Grid2>
              </>
            ) : null}
            <Grid2>
              <LoadingButton buttonProps={{ variant: 'contained', onClick: onSearch, loading: isLoading }}>Søk</LoadingButton>
            </Grid2>
          </Grid2>
          {erpSync ? (
            <Paper sx={{ padding: 2, mt: 2 }}>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Ordreinfo:
              </Typography>
              <Grid2
                container
                spacing={3}
                sx={{
                  alignItems: 'center',
                }}
              >
                {erpSync.salesOrderLink ? (
                  <Grid2>
                    <Typography>Salgsordre: </Typography>
                    <Link href={erpSync.salesOrderLink} target="_blank" rel="noopener noreferrer">{erpSync.salesOrderLink?.split('=').pop()}</Link>
                  </Grid2>
                ) : null}
                {erpSync.installOrderLink ? (
                  <Grid2>
                    <Typography>Monteringsordre: </Typography>
                    <Link href={erpSync.installOrderLink} target="_blank" rel="noopener noreferrer">{erpSync.installOrderLink?.split('=').pop()}</Link>
                  </Grid2>
                ) : null}
                <Grid2>
                  <Typography>
                    Ordretype:
                  </Typography>
                  {erpSync.orderType}
                </Grid2>
                <Grid2>
                  <Typography>
                    Dato opprettet:
                  </Typography>
                  {DateUtils.getLocalizedDateTime(erpSync.created)}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO AC
                    {erpSync.isACT ? 'T ' : ' '}
                    Ordre ID:
                    {' '}
                  </Typography>
                  {erpSync.acErpId}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO FJ Klima Hjelpeordre ID:
                    {' '}
                  </Typography>
                  {erpSync.fjErpId}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO FJ Klima Final ID:
                    {' '}
                  </Typography>
                  {erpSync.finalErpId}
                </Grid2>
                <Grid2>
                  <Typography>
                    Masterplan Ordre ID:
                    {' '}
                  </Typography>
                  {erpSync.mrpSaleId}
                </Grid2>
                <Grid2>
                  <Typography>
                    Masterplan Ship ID:
                  </Typography>
                  {erpSync.mrpShipId}
                </Grid2>
                <Grid2>
                  <Typography>
                    Masterplan Faktura ID:
                  </Typography>
                  {erpSync.mrpInvoiceId}
                </Grid2>
                <Grid2>
                  <Typography>
                    Masterplan Fakturert Dato:
                  </Typography>
                  {DateUtils.getLocalizedDateTime(erpSync.mrpInvoiceDate)}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO AC
                    {erpSync.isACT ? 'T ' : ' '}
                    Ordre Fakturert?
                  </Typography>
                  {erpSync.acErpInvoiced ? 'Ja' : 'Nei'}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO FJ Klima Ordre Fakturert?
                  </Typography>
                  {erpSync.fjErpInvoiced ? 'Ja' : 'Nei'}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO AC Siste ordrestatus
                  </Typography>
                  {erpSync.acOrderStatus}
                </Grid2>
                <Grid2>
                  <Typography>
                    24SO FJ Klima Siste ordrestatus
                  </Typography>
                  {erpSync.fjOrderStatus}
                </Grid2>
              </Grid2>
              {erpSync.mrpPickList?.length ? (
                <>
                  <Typography sx={{ mt: 2 }}>Masterplan Plukk info:</Typography>
                  <List>
                    {erpSync.mrpPickList.map((pick: MrpPickListInner) => (
                      <ListItem>
                        <ListItemIcon>
                          <HorizontalRule />
                        </ListItemIcon>
                        <ListItemText>
                          LineID:
                          {' '}
                          {pick.lineItemId}
                          {' - '}
                          Identifier:
                          {' '}
                          {pick.identifiersPicked}
                          {' - '}
                          PickListID:
                          {' '}
                          {pick.pickListId}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </>
              ) : null}
            </Paper>
          ) : (
            (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {!erpSyncCommand || isLoading ? null : (
                  <Alert severity="error">Ingen ordre funnet på søket.</Alert>
                )}
              </>
            )
          )}

          <Divider sx={{ my: 2 }} />
          <Typography variant="h5">INFO</Typography>
          <Typography variant="h6" gutterBottom>
            Dry Run: Kjør funksjonen og se hva som ville blitt eventuelt endret i ordren / gjort med ordren.
            Eks. Masterplan Plukk vil vise hvilke stock den ville ha plukket.
          </Typography>
          {erpSync && erpSyncCommand ? (
            <>
              <Divider sx={{ mb: 4, mt: 2 }} />
              {!erpSync.mrpSaleId && erpSync.fjErpId ? (
                <Alert variant="filled" severity="warning">
                  <Typography variant="h4">Ingen Masterplan ordre funnet!</Typography>
                  <Typography variant="h6">Det skal vere en task som kjører regelmessig og burde ha funnet denne, men noen (eldre) ordre kan ha mistet denne tasken.</Typography>
                  <Typography variant="h6">Denne finner Masterplan ordre, oppdaterer Warehouse og kjører en sync av 24SO AC innpris</Typography>
                  <Button
                    variant="contained"
                    onClick={() => mrpFindMRPOrderMutation.mutate(
                      { cpproId: erpSync.cpproId.toString() },
                      { onSuccess: () => queryClient.invalidateQueries(erpSyncQuery) },
                    )}
                  >
                    Finn MRP ordre
                  </Button>
                  {mrpFindMRPOrderMutation.data || mrpFindMRPOrderMutation.isError ? (
                    <Typography
                      variant="h6"
                      sx={{
                        color: mrpFindMRPOrderMutation.isError ? 'red' : 'lightgreen',
                      }}
                    >
                      Status:
                      {' '}
                      {mrpFindMRPOrderMutation.isError ? JSON.stringify(mrpFindMRPOrderMutation.error.response?.data ?? {}) : 'OK - fant ordre'}
                    </Typography>
                  ) : null}
                </Alert>
              ) : null}
              {erpSync.cpproInstallId ? (
                <Paper sx={{ padding: 2 }}>
                  <Typography variant="h6">Alternativ prosess: Kjør steg 1-2 + 4-5 automatisk</Typography>
                  <Typography gutterBottom>
                    {/* eslint-disable-next-line max-len */}
                    Dette vil kjøre hele prosessen med fakturering for ordren slik den ville ha gjort det i en normal ordre. Her får en kun respons av steg 1 tilbake (kun ved feil i steg 1). Resten av stegene vil prøve på nytt automatisk.
                  </Typography>
                  {/* eslint-disable-next-line max-len */}
                  <Typography>NB! Det kan ta minst 1 time før steg 4 / fakturering i Masterplan blir utført (prøver på nytt med 1 time mellom forsøk).</Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setRanInvoicingProcess(true);
                      installCompleteMutation.mutate(
                        { installCPproId: erpSync.cpproInstallId.toString(), continueProcess: 'true' },
                        {
                          onSuccess: () => { queryClient.invalidateQueries(erpSyncQuery); },
                        },
                      );
                    }}
                  >
                    Kjør Fakturering
                  </Button>
                  {ranInvoicingProcess && (installCompleteMutation.data || installCompleteMutation.isError) ? (
                    <Accordion defaultExpanded={installCompleteMutation.isError || installCompleteDryRun}>
                      <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: installCompleteMutation.isError ? 'red' : 'green' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                      <AccordionDetails>
                        <Alert variant="filled" severity={installCompleteMutation.isError ? 'error' : 'success'}>
                          <pre>
                            <code>
                              {JSON.stringify(installCompleteMutation.isError
                                ? installCompleteMutation.error.response?.data
                                : installCompleteMutation.data ?? { }, null, 2)}
                            </code>
                          </pre>
                        </Alert>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">Steg 1: Monteringsordre er utfylt og klar til fakturering</Typography>
                  <Typography gutterBottom>
                    Dette steget vil oppdatere 24SO AC ordren og Masterplan ordren sine ordrelinjer om det er endringer i produktlinjer i ordren.
                    <b>
                      {' '}
                      NB! Vil nok kun gjelde nye / kommende monteringsordre og ikke gamle ordre.
                      <br />
                    </b>
                    <b><i>Hopp over dette steget om ordren er OK i 24SO AC(T) og Masterplan, og skal ikke ha noen endringer uansett.</i></b>
                  </Typography>
                  <Stack direction="row" sx={{ marginBottom: 2 }}>
                    <Tooltip title="Dette vil slette linjer fra 24SO AC / Masterplan ordre om det er nokon linjer i monteringsordren som har antall = 0">
                      <FormControlLabel
                        label="Enable delete?"
                        control={(
                          <Checkbox
                            checked={installCompleteEnableDelete}
                            onChange={(_, checked) => setInstallCompleteEnableDelete(checked)}
                          />
                  )}
                      />
                    </Tooltip>
                    <FormControlLabel
                      label="Dry Run"
                      control={(
                        <Checkbox
                          checked={installCompleteDryRun}
                          onChange={(_, checked) => setInstallCompleteDryRun(checked)}
                        />
                )}
                    />
                    <Button
                      color={installCompleteDryRun ? 'success' : 'error'}
                      onClick={() => onUpdateOrderButtonClick(erpSync)}
                      variant="contained"
                    >
                      Oppdater ordrelinjer
                    </Button>
                  </Stack>
                  {/* set severity based on feedback data! */}
                  {installCompleteMutation.data || installCompleteMutation.isError ? (
                    <Accordion defaultExpanded={installCompleteMutation.isError || installCompleteDryRun}>
                      <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: installCompleteMutation.isError ? 'red' : 'lightgreen' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                      <AccordionDetails>
                        <Alert variant="filled" severity={installCompleteMutation.isError ? 'error' : 'success'}>
                          <pre>
                            <code>
                              {JSON.stringify(installCompleteMutation.isError
                                ? installCompleteMutation.error.response?.data
                                : installCompleteMutation.data ?? { }, null, 2)}
                            </code>
                          </pre>
                        </Alert>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                  {erpSyncOnlyAC ? (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6">Steg 1A: Ordre har ikke 24SO FJ Klima eller Masterplan ordre</Typography>
                      <Typography gutterBottom>
                        Her kan en oppdatere ordre med status &quot;Til fakturering&quot; direkte.
                        {' '}
                        {/* eslint-disable-next-line max-len */}
                        <b>NB! Det kan vere at dette er feil og det manglet ordrelinjer i 24SO AC ordren som skulle gjort at den skulle ha laget en 24SO FJ Klima ordre, eller at 24SO FJ Klima ordren feiler ved opprettelse.</b>
                      </Typography>
                      <Stack direction="row" sx={{ marginBottom: 2 }}>
                        <Button
                          onClick={() => onSetACInvoicedButtonClick(erpSync)}
                          variant="contained"
                        >
                          Oppdater ordrestatus
                        </Button>
                      </Stack>
                      {/* set severity based on feedback data! */}
                      {acInvoiceMutation.data || acInvoiceMutation.isError ? (
                        <Accordion defaultExpanded={acInvoiceMutation.isError}>
                          <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: acInvoiceMutation.isError ? 'red' : 'lightgreen' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                          <AccordionDetails>
                            <Alert variant="filled" severity={acInvoiceMutation.isError ? 'error' : 'success'}>
                              <pre>
                                <code>
                                  {JSON.stringify(acInvoiceMutation.isError
                                    ? acInvoiceMutation.error.response?.data
                                    : acInvoiceMutation.data ?? { }, null, 2)}
                                </code>
                              </pre>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6">Steg 2: Plukk i Masterplan</Typography>
                      <Typography gutterBottom>
                        Denne vil hente serienummer og prøve å finne stock for alle ordrelinjer i Masterplan ordre
                        {' '}
                        for å deretter kjøre plukk i Masterplan.
                      </Typography>
                      <Stack direction="row" sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                          label="Dry Run"
                          control={(
                            <Checkbox
                              checked={mrpPickDryRun}
                              onChange={(_, checked) => setMrpPickDryRun(checked)}
                            />
                    )}
                        />
                        <Button
                          color={mrpPickDryRun ? 'success' : 'error'}
                          onClick={() => onMrpPickButtonClick(erpSync)}
                          variant="contained"
                        >
                          Plukk ordre
                        </Button>
                      </Stack>
                      {/* set severity based on feedback data! */}
                      {mrpPickMutation.data || mrpPickMutation.isError ? (
                        <Accordion defaultExpanded={mrpPickMutation.isError || mrpPickDryRun}>
                          <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: mrpPickMutation.isError ? 'red' : 'lightgreen' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                          <AccordionDetails>
                            <Alert variant="filled" severity={mrpPickMutation.isError ? 'error' : 'success'}>
                              <pre>
                                <code>
                                  {JSON.stringify(mrpPickMutation.isError
                                    ? mrpPickMutation.error.response?.data
                                    : mrpPickMutation.data ?? { }, null, 2)}
                                </code>
                              </pre>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6">Steg 3: Ship i Masterplan</Typography>
                      {/* eslint-disable-next-line max-len */}
                      <Typography>Dette steget vil kjøre Ship i Masterplan. NB! API er klart, men ikkje i prod ennå så denne vil feile for nå. Dette må bli gjort manuelt.</Typography>
                      <Stack direction="row" sx={{ marginBottom: 2 }}>
                        <Button
                          onClick={() => onMrpShipButtonClick(erpSync)}
                          variant="contained"
                        >
                          Ship ordre
                        </Button>
                      </Stack>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6">Steg 4: Fakturere i Masterplan</Typography>
                      <Typography>Dette steget vil fakturere Masterplan ordren. Kan kun bli gjort om Pick og Ship er fullført i Masterplan.</Typography>
                      <Stack direction="row" sx={{ marginBottom: 2 }}>
                        <Button
                          onClick={() => onMrpInvoiceButtonClick(erpSync)}
                          variant="contained"
                        >
                          Fakturere ordre
                        </Button>
                      </Stack>
                      {mrpInvoiceMutation.data || mrpInvoiceMutation.isError ? (
                        <Accordion defaultExpanded={mrpInvoiceMutation.isError}>
                          <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: mrpInvoiceMutation.isError ? 'red' : 'lightgreen' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                          <AccordionDetails>
                            <Alert variant="filled" severity={mrpInvoiceMutation.isError ? 'error' : 'success'}>
                              <pre>
                                <code>
                                  {JSON.stringify(mrpInvoiceMutation.isError
                                    ? mrpInvoiceMutation.error.response?.data
                                    : mrpInvoiceMutation.data ?? { }, null, 2)}
                                </code>
                              </pre>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6">Steg 4A: Ordre er allerede fakturert manuelt i Masterplan</Typography>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>Klikk her for å vise mer info om steg 4A:</AccordionSummary>
                        <AccordionDetails>
                          {/* eslint-disable-next-line max-len */}
                          <Typography>Om ordre er manuelt fakturert i Masterplan, sett dato / fakturanr her. NB! Dato må vere før ordren var fakturert. Er du usikker, velg 1-2 dager tidlegere for å vere sikker.</Typography>
                          <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                            <TextField
                              type="datetime-local"
                              value={invoiceDate}
                              onChange={(e) => setInvoiceDate(e.target.value)}
                              title="Fakturadato"
                              label="Fakturadato"
                              slotProps={{
                                inputLabel: { shrink: true },
                              }}
                            />
                            <TextField
                              value={invoiceId}
                              onChange={(e) => setInvoiceId(e.target.value)}
                              title="Fakturanummer (valgfritt)"
                              label="Fakturanummer (valgfritt)"
                            />
                            <Button
                              onClick={() => mrpManuallyInvoicedMutation.mutate({
                                cpproId: erpSync.cpproId.toString(),
                                postMRPManuallyInvoicedRequest: {
                                  invoicedDateTime: DateUtils.convertFromMUI(invoiceDate) ?? '',
                                  invoiceId: invoiceId || '-1',
                                },
                              }, {
                                onSuccess: () => queryClient.invalidateQueries(erpSyncQuery),
                              })}
                              variant="contained"
                              disabled={!invoiceDate}
                            >
                              Oppdater dato / nummer
                            </Button>
                          </Stack>
                          {mrpManuallyInvoicedMutation.data || mrpManuallyInvoicedMutation.isError ? (
                            <Accordion defaultExpanded={mrpManuallyInvoicedMutation.isError}>
                              <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: mrpManuallyInvoicedMutation.isError ? 'red' : 'lightgreen' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                              <AccordionDetails>
                                <Alert variant="filled" severity={mrpManuallyInvoicedMutation.isError ? 'error' : 'success'}>
                                  <pre>
                                    <code>
                                      {JSON.stringify(mrpManuallyInvoicedMutation.isError
                                        ? mrpManuallyInvoicedMutation.error.response?.data
                                        : mrpManuallyInvoicedMutation.data ?? { }, null, 2)}
                                    </code>
                                  </pre>
                                </Alert>
                              </AccordionDetails>
                            </Accordion>
                          ) : null}
                        </AccordionDetails>
                      </Accordion>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6">Steg 5: Fakturer i 24SO AC og inaktivere 24SO FJ Klima Hjelpeordre</Typography>
                      <Typography gutterBottom>
                        Dette steget vil finne den nye 24SO FJ Klima ordren (den faktiske/endelige ordren) og inaktivere hjelpeordren,
                        {' '}
                        oppdatere innpris i 24SO AC ordren en siste gang, og til slutt sette 24SO AC ordrestatus til &quot;Til fakturering&quot;.
                      </Typography>
                      <Stack direction="row" sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                          label="Dry Run"
                          control={(
                            <Checkbox
                              checked={invoiceAndInactivateDryRun}
                              onChange={(_, checked) => setInvoiceAndInactivateDryRun(checked)}
                            />
                    )}
                        />
                        <Button
                          color={invoiceAndInactivateDryRun ? 'success' : 'error'}
                          onClick={() => onInvoiceAndInactivateButtonClick(erpSync)}
                          variant="contained"
                        >
                          Oppdater ordre
                        </Button>
                      </Stack>
                      {invoiceAndInactivateMutation.data || invoiceAndInactivateMutation.isError ? (
                        <Accordion defaultExpanded={invoiceAndInactivateMutation.isError || invoiceAndInactivateDryRun}>
                          <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: invoiceAndInactivateMutation.isError ? 'red' : 'lightgreen' }}>Resultat (klikk for å vise/skjule): </AccordionSummary>
                          <AccordionDetails>
                            <Alert variant="filled" severity={invoiceAndInactivateMutation.isError ? 'error' : 'success'}>
                              <pre>
                                <code>
                                  {JSON.stringify(invoiceAndInactivateMutation.isError
                                    ? invoiceAndInactivateMutation.error.response?.data
                                    : invoiceAndInactivateMutation.data ?? { }, null, 2)}
                                </code>
                              </pre>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </>
                  )}
                </Paper>
              ) : (
                <Alert variant="filled" severity="error">
                  <Typography variant="h4">Ingen monteringsordre funnet!</Typography>
                  <Typography variant="h6">Mamut ordrenr blir fortsatt brukt som ordrenavn på monteringsordre, så en må søke minst en gang på mamutnummer i monteringsordrefeltet.</Typography>
                  <Typography variant="h6">Etter dette er gjort er koblingen lagret og en kan søke på andre typer felt.</Typography>
                </Alert>
              )}

            </>
          ) : null}
        </Box>
        <ConfirmDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={onConfirm}
          descriptionText="Dette vil oppdatere ordren! Er du sikker?"
        />
      </Box>
    )
  );
};

export default ERPInvoicing;
