import {
  Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid2,
} from '@mui/material';
import React, { useCallback } from 'react';
import MonitorDateSearch from './MonitorDateSearch.js';
import { MonitorTypes } from '../index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

type MonitorEmailSMSSearchProps = {
  state: Required<MonitorTypes.GetEmailsCommand | MonitorTypes.GetSMSesCommand>,
  setState: React.Dispatch<React.SetStateAction<Required<MonitorTypes.GetEmailsCommand | MonitorTypes.GetSMSesCommand>>>,
  onSearch: () => void,
  loading?: boolean,
};

const MonitorEmailSMSSearch = (props: MonitorEmailSMSSearchProps) => {
  const {
    state, setState, onSearch, loading,
  } = props;

  const setStart = useCallback((start: string) => setState({ ...state, startTime: start }), [setState, state]);
  const setEnd = useCallback((end: string) => setState({ ...state, endTime: end }), [setState, state]);

  return (
    (
      <Grid2
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <MonitorDateSearch start={state.startTime} end={state.endTime} setStart={setStart} setEnd={setEnd} />
        <Grid2>
          <FormControl>
            <InputLabel id="status_label" shrink sx={{ backgroundColor: 'white' }}>Status</InputLabel>
            <Select
              labelId="status_label"
              value={state.status}
              onChange={(e) => setState({ ...state, status: e.target.value as MonitorTypes.EmailStatus })}
              label="Status"
              fullWidth
            >
              {MonitorTypes.EmailStatuses.map((s) => (
                <MenuItem key={s} value={s}>{s}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2>
          <TextField
            value={state.to}
            onChange={(e) => setState({ ...state, to: e.target.value })}
            label="To"
          />
        </Grid2>
        <Grid2>
          <TextField
            value={state.from}
            onChange={(e) => setState({ ...state, from: e.target.value })}
            label="From"
          />
        </Grid2>
        {/* <Grid2>
        <FormControl>
          <InputLabel id="type_label" shrink sx={{ backgroundColor: 'white' }}>Type</InputLabel>
          <Select
            labelId="type_label"
            value={state.type}
            onChange={(e) => setState({ ...state, type: e.target.value })}
            displayEmpty
            label="type"
          >
            <MenuItem value="">Any</MenuItem>
            <MenuItem value="Ordrebekreftelse">Ordrebekreftelse</MenuItem>
            <MenuItem value="Tilbud">Tilbud</MenuItem>
          </Select>
        </FormControl>
      </Grid2> */}
        <Grid2>
          <TextField
            value={state.orderId}
            onChange={(e) => setState({ ...state, orderId: e.target.value })}
            label="OrderID"
            sx={{ width: 100 }}
          />
        </Grid2>
        <Grid2>
          <TextField
            type="number"
            value={state.retriesMoreThan}
            onChange={(e) => setState({ ...state, retriesMoreThan: Number(e.target.value) })}
            label="Min retries"
            sx={{ width: 100 }}
          />
        </Grid2>
        <Grid2>
          {loading !== undefined ? (
            <LoadingButton
              buttonProps={{
                loading,
                onClick: onSearch,
              }}
            >
              <span>Search</span>
            </LoadingButton>
          ) : (
            <Button variant="contained" onClick={onSearch}>Search</Button>
          )}
        </Grid2>
      </Grid2>
    )
  );
};

export default MonitorEmailSMSSearch;
