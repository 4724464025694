import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import {
  TextField, Typography, Box, Grid2,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import type { Dayjs } from 'dayjs';
import { ERPMutations } from '../../../mutations/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { infoToast } from '../../../../CPpro/Utils/toast.js';

const ERPTools = () => {
  const [salesOrderName, setSalesOrderName] = React.useState('');
  const [deliveryDate, setDeliveryDate] = React.useState<Dayjs | null>(null);
  const { mutateAsync, isPending } = useMutation(ERPMutations.acCreateOrderTaskMutation);

  const onCreate = useCallback(async () => {
    await mutateAsync({ postCreateOrderRequest: { salesOrderName, deliveryDate: deliveryDate?.format('YYYY-MM-DD') } }, {
      onSuccess: () => {
        setSalesOrderName('');
        setDeliveryDate(null);
        infoToast('Ordre task opprettet', true, 6000);
      },
    });
  }, [deliveryDate, mutateAsync, salesOrderName]);

  return (
    <Box>
      <MonitorPageTitle title="ERP Verktøy" />
      <Typography variant="h5">
        Opprette 24SO AC Ordre fra CPPro salgsordre (task - prøver automatisk å opprette ordre)
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2>
          <DatePicker label="Leveringsdato" value={deliveryDate} onChange={setDeliveryDate} />
        </Grid2>
        <Grid2>
          <TextField title="Ordrenavn" label="Ordrenavn" value={salesOrderName} onChange={(e) => setSalesOrderName(e.target.value)} />
        </Grid2>
        <Grid2>
          <LoadingButton
            save
            buttonProps={{
              variant: 'contained',
              onClick: onCreate,
              loading: isPending,
              disabled: !salesOrderName,
            }}
          >
            Opprett ordre task
          </LoadingButton>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ERPTools;
